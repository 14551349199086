import { Component } from "react";

import Utils from "./Utils";
import BarsByLockDays from "./Stats/BarsByLockDays";
import config from "./config.json";

import "./css/Statistics.css";

class Statistics extends Component {
    getTopData() {
        console.log("Called Get Top Data");
        
        let sumAmount = 0;
        let sumAmountMulDays = 0;
        let totalTickets1 = 0;
        let totalTickets2 = 0;
        for (const i in this.props.data.stakers) {
            for (const stake of this.props.data.stakers[i]) {
                if (stake.unstakeTx) continue;

                sumAmount += Number(stake.amount);
                sumAmountMulDays += Number(stake.amount) * (stake.lockDays);
                totalTickets1 += Math.floor(Number(stake.shares) / config.ticket1);
                totalTickets2 += Math.floor(Number(stake.shares) / config.ticket2);
            }
        }

        const averageDaysLocked = Math.round(sumAmountMulDays / sumAmount);
        const totalStakeAmount = Number(this.props.data.totalStaked);
        return { totalStakeAmount, averageDaysLocked, totalTickets1, totalTickets2 };
    }


    render() 
    {
        const topData = this.getTopData();
        topData.totalStakeAmount = Utils.numberWithUnits(topData.totalStakeAmount);
        topData.totalTickets1 = Utils.numberWithCommas(topData.totalTickets1);
        topData.totalTickets2 = Utils.numberWithCommas(topData.totalTickets2);
        const topDataLabels = ["Total VR Staked", "Average days staked", "VR Lands tickets", "Mystery Airdrop tickets"];
        const topDataElements = Object.values(topData).map((value, i) => {
            return (
                <li key={i}> 
                    <div className="value"> {value} </div>
                    <div className="label"> {topDataLabels[i]}  </div>
                </li>
            )
        });
        
        return (
            <div id="statistics">
                <h1> VR Staking Statistics </h1>
                <div className="glowing-tabs">
                    <ul>
                        {topDataElements}
                    </ul>
                </div>
                <h2> Staking amount by staking periods </h2>

                <BarsByLockDays data={this.props.data}></BarsByLockDays>
            </div>
        )
    }
}

export default Statistics;