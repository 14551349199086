import { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
    render() 
    {
        return (
            <header id="header" className="light-bg">
                <div>
                    <Link to="/">
                        <img data-aspectratio="321/49"
                            src="./VictoriaVRLogo.svg" 
                            data-src="https://victoriavr.com/wp-content/uploads/2021/11/logo-victoria-vr-new.svg" 
                            alt="loading" height="36" width="300" loading="lazy"> 
                        </img>  
                    </Link>
                    <ul id="header-right">
                        <li> <Link to="/"> Statistics </Link> </li>
                        <li> <Link to="/calculator"> Calculator </Link> </li>
                        <li> <a href="https://stake.victoriavr.com/" target="_blank"> Stake now </a> </li>
                    </ul>
                </div>
            </header>
        )
    }
}

export default Header;